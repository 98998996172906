<template>
    <div class="database">
      <p>Ship the Car-Database (csv-upload) to staging or production.</p>
      <el-upload
        class="dragndrop"
        accept=".csv"
        drag
        action=""
        :on-change="handleChange"
        :on-remove="handleRemove"
        :auto-upload="false"
        >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
      </el-upload>

      <div class="update-type-switch-wrap">
        <el-switch
          style="display: block"
          v-model="drop"
          active-color="#ff4949"
          inactive-color="#13ce66"
          active-text="Replace"
          inactive-text="Patch">
        </el-switch>
      </div>

      <el-button @click="upload" :loading="loading" :disabled="isProd || files.length !== 1">Ship to staging</el-button>
      <el-button @click="upload" :loading="loading" :disabled="!isProd || files.length !== 1">Ship to production</el-button>
    </div>
</template>

<script>
import { mapState } from "vuex";
import api from '@/services/api';

export default {
  name: 'CarDatabaseUpload',
  data() {
    return {
      drop: false,
      loading: false,
      files: []
    }
  },
  computed: {
    ...mapState({
      projects: state => state.projects.data,
      env: state => state.settings.env
    }),
    isProd() {
      return this.env === "production";
    }
  },
  methods: {
    async upload() {
      this.loading = true;
      try {
        await api.uploadCarDatabase(this.files[0].raw, this.drop);
        this.$alert("Die Datenbank wurde erfolgreich übertragen und importiert.", "Erfolgreich", { confirmButtonText: 'OK' });
      } catch (error) {
        this.$alert("Leider ist etwas schief gelaufen", "Fehler", { confirmButtonText: 'OK' });
      }
      this.loading = false;
    },
    handleChange(file) {
      this.files = [...this.files, file];
    },
    handleRemove(file) {
      this.files = this.files.filter(f => {
        return f.uid !== file.uid;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.database {
  padding: 10px 40px;

  p {
    margin: 30px 0;
  }

  .dragndrop {
    margin: 20px 0;
    width: 360px;
  }

  .update-type-switch-wrap {
    margin: 20px 5px;
  }
}
</style>
