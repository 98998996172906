<template>
  <div class="page">
    <h1>Ship <b>templates</b></h1>
    <ShipTemplates />

    <h1>Ship <b>database</b></h1>
    <CarDatabaseUpload />

    <h1>Ship <b>apps</b></h1>
    <div class="apps">

      <div class="nothing-text" v-if="filteredProjects.length === 0">
        Currently nothing to ship :)
      </div>

      <el-card v-for="project in filteredProjects" :key="project.name" class="box-card">
        <div slot="header">
          <span>{{ project.name }}</span>
        </div>
        <Pipeline :project="project" :disabled="!isProd" />
        <div class="sep"></div>
        <div>
          <div v-for="logLine in project.changeLog" :key="logLine.sha" class="changelog-line" @click="openLogLine(logLine)">
            {{ logLine.message }}
          </div>
        </div>

      </el-card>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pipeline from '@/components/Pipeline'
import CarDatabaseUpload from '@/components/CarDatabaseUpload'
import ShipTemplates from '@/components/ShipTemplates'
export default {
  name: 'Ship',
  computed: {
    ...mapState({
      projects: state => state.projects.data,
      env: state => state.settings.env
    }),
    filteredProjects() {
      return Object.values(this.projects || {}).filter(p => p.dirtyProd);
    },
    isProd() {
      return this.env === "production";
    },
  },
  components: {
    Pipeline,
    CarDatabaseUpload,
    ShipTemplates
  },
  methods: {
    openLogLine(logLine) {
      window.open(logLine.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: normal;
  margin-top: 100px;

  &:first-of-type {
    margin-top: 0;
  }
}

.apps {
  display: flex;
  flex-wrap: wrap;

  .box-card {
    width: 600px;
    margin: 20px;
    border-radius: 20px;

    .sep {
      border-bottom: 2px solid #eceef5;
    }
  }
}

.nothing-text {
  color: rgb(151, 151, 151);
  margin: 50px;
}

.changelog-line {
  margin: 14px 0;
  padding: 0 15px;
  border-left: 2px solid #78bdc4;
  cursor: pointer;
}
</style>

