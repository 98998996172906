<template>
    <div class="templates">
      <p>
        Publish all templates to staging or production. The templates(.hbs) stored in the git repo will be pushed into the database.
        <br><br>Templates are published to the staging environment by default after clicking the "Publish-Button" in the top right corner of the template builder.
        <br><br>To publish to production, you have to click the "Publish to production" button below.
      </p>
      <ul>
        <li v-for="tk in templateKeys" :key="tk">{{tk}}</li>
      </ul>
      <el-button @click="ship" :loading="loading" :disabled="isProd">Ship to staging</el-button>
      <el-button @click="ship" :loading="loading" :disabled="!isProd">Ship to production</el-button>
    </div>
</template>

<script>
import { mapState } from "vuex";
import api from '@/services/api';
import constants from '@/constants'

export default {
  name: 'ShipTemplates',
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      projects: state => state.projects.data,
      env: state => state.settings.env
    }),
    isProd() {
      return this.env === "production";
    },
    templateKeys() {
      return Object.keys(constants.templates).reduce((res,k) => {
        for (const templateKey of constants.templates[k]) {
          res.push(k + "_" + templateKey);
        }
        return res;
      }, []);
    }
  },
  methods: {
    async ship() {
      this.loading = true;
      try {
        await api.pushTemplatesToDatabse(this.templateKeys);
        this.$alert("Die Templates wurden erfolgreich übertragen und importiert.", "Erfolgreich", { confirmButtonText: 'OK' });
      } catch (error) {
        this.$alert("Leider ist etwas schief gelaufen", "Fehler", { confirmButtonText: 'OK' });
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.templates {
  padding: 10px 40px;

  p {
    margin: 30px 0;
  }
}
</style>
