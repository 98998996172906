<template>
    <div class="pipeline">
        <div class="step success">
            <fa-icon size="2x" :icon="['fab', 'github']" />
            <span class="explanation">develop</span>
        </div>
        <div class="arrow"><fa-icon size="2x" icon="angle-right" /></div>

        <div v-if="project.dirtyProd" class="ship">
            <div class="info">Production is outdated</div>
            <button :disabled="loading || disabled" @click="ship">{{ loading ? "shipping ..." : "Ship to Production" }}</button>
        </div>
        <div v-else class="ship success">
            <div class="info">Nothing to ship <br> Production up to date</div>
        </div>

        <div class="arrow"><fa-icon size="2x" icon="angle-right" /></div>
        <div :class="{ step: 1, success: !project.dirtyProd }">
            <fa-icon size="2x" :icon="['fab', 'github']" />
            <span class="explanation">master</span>
        </div>
    </div>
</template>

<script>
import api from '@/services/api'
import { FETCH_PROJECTS } from '@/vuex/modules/projects/actions'

export default {
  name: 'Pipeline',
  props: ['project', 'disabled'],
  data() {
      return {
          loading: false
      }
  },
  methods: {
      async ship() {
          this.loading = true;
          await api.mergeDevelop(this.project.name);
          await this.$store.dispatch(FETCH_PROJECTS);
          this.loading = false
      }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.pipeline {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 15px;
      color: gray;
  }

  .ship {
    width: 300px;
    height: 64px;
    border-radius: 35px;
    box-sizing: border-box;
    padding: 8px;
    line-height: 22px;
    border: 2px dashed gray;
    text-align: center;

    .info {
        color: gray;
    }

    button {
        border: none;
        background: transparent;
        font-size: 16px;
        outline: none;
        border-radius: 30px;
        padding: 7px 22px;
        color: $ice;
        cursor: pointer;

        &:disabled {
            cursor: default;
            color: rgb(218, 218, 218);
        }
    }

    &.success { 
        border: 2px solid rgb(147, 189, 147);
        
        .info {
            color: rgb(124, 184, 124);
        }
    }
  }

  .step {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px dashed rgb(206, 206, 206);
    box-sizing: border-box;
    color: rgb(206, 206, 206);
    display: flex;
    justify-content: center;
    align-items: center;

    &.success {
        color: white;
        background: $ice;
        border: 2px dashed $ice;
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
    }

    .explanation {
        cursor: default;
        display: block;
        font-size: 11px;
        padding: 3px 0;
        border-radius: 25px;
        background: #cecece;
        color: white;
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        text-align: center;
    }
  }
}
</style>
